import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import SubscribedFeedback from "../components/SubscribedFeedback"

const SubscribedFeedbackPage = () => (
  <Layout>
    <SEO
      title="Thesis | Give feedback"
      keywords={[]}
      description="Take the chance to provide feedback."
    />
    <SubscribedFeedback />
  </Layout>
)

export default SubscribedFeedbackPage
