import React, { Component } from "react"
import PropTypes from "prop-types"
import { noop } from "lodash"
import ErrorMessage from "../ErrorMessage"
import styles from "../styles.module.less"

export default class SubscribedFeedback extends Component {
  static defaultProps = {
    complete: false,
    handleChange: noop,
    name: "",
    email: "",
    message: "",
    errors: { name: "", email: "", message: "" },
    failure: "",
  }
  static propTypes = {
    complete: PropTypes.bool,
    handleChange: PropTypes.func,
    name: PropTypes.string,
    email: PropTypes.string,
    message: PropTypes.string,
    errors: PropTypes.object,
    failure: PropTypes.string,
  }
  handleInputChange = e => {
    this.props.handleChange(e)
  }
  render() {
    const { message, errors } = this.props
    return (
      <section className={styles.InnerForm}>
        <div className={styles.inner}>
          <div>
            <h2>Comments</h2>
            <div className={styles.wrap}>
              <div className={styles.input}>
                <textarea
                  name="message"
                  type="text"
                  onChange={this.handleInputChange}
                  value={message}
                />
                <span className={styles.borda}></span>
              </div>
            </div>
            <ErrorMessage error={errors.message} />
          </div>

          <nav className={styles.links}>
            <button
              className={styles.submit}
              label="Submit"
              type="submit"
              onClick={this.handleValidation}
            >
              <span>Submit</span>
            </button>
            <button
              className={styles.ctaMobile}
              label="StaySubscribed"
              type="staySubscribedMobile"
              onClick={this.handleValidation}
              action="../../pages/subscribed-thankyou"
            >
              <span>Stay subscribed</span>
            </button>
          </nav>
        </div>
      </section>
    )
  }
}
