import React from "react"

import styles from "../styles.module.less"

// eslint-disable-next-line react/prop-types
const ErrorMessage = ({ error }) => {
  if (!error) return null
  return <span className={styles.error}>{error}</span>
}

export default ErrorMessage
